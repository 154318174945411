<template>
  <div>
    <div class="head">
      <div class="head_left">
        <!-- <div
          @click="selectTab(item)"
          v-for="(item, i) in tabList"
          :key="i"
          :class="{ tab_item: true, tab_select: seleceTabId == item.id }"
        >
          {{ item.name }}
        </div> -->
      </div>
      <div class="search_box">
        <el-input
          v-model.trim="contentTitle"
          placeholder="请输入搜索关键字"
          @keydown.enter.native="keySearch"
        ></el-input>
        <span class="search_icon el-icon-search" @click="keySearch"></span>
      </div>
    </div>
    <div v-loading="loading" class="news">
      <div v-if="list.length > 0">
        <template v-for="(item, i) in list">
          <!-- 顶部第一条新闻 -->
          <div
            class="news_first"
            :key="i"
            v-if="i == 0"
            @click="toDetail(item.id)"
          >
            <img :src="item.contentImgUrl || $defaultImg" class="first_img" />
            <div class="first_bot">
              <div class="first_title">
                {{ item.contentTitle }}
              </div>
              <div class="first_content">
                {{ item.contentDescription }}
              </div>
            </div>
          </div>
          <!-- -------------------- -->

          <!-- 第2~5条新闻 -->
          <div
            v-else-if="i > 0 && i < 5"
            :key="i"
            class="news_two"
            @click="toDetail(item.id)"
          >
            <img
              :src="item.contentImgUrl || $defaultImg"
              class="news_two_img"
            />
            <div class="news_two_info">
              <div class="news_two_title">
                {{ item.contentTitle }}
              </div>
              <div class="news_two_time">{{ item.contentDatetime | timeFormat }}</div>
              <div class="news_two_content">
                {{ item.contentDescription }}
              </div>
            </div>
          </div>
          <!-- ------------------- -->

          <!-- 第6条及以后 -->
          <div :key="i" v-else class="news_thr" @click="toDetail(item.id)">
            <div class="news_thr_time">
              <div class="news_thr_time_data">{{ item.time[2] }}</div>
              <div>{{ item.time[0] + "." + item.time[1] }}</div>
            </div>
            <div class="news_thr_info">
              <div class="news_thr_title">
                {{ item.contentTitle }}
              </div>
              <div class="news_thr_content">
                {{ item.contentDescription }}
              </div>
            </div>
          </div>
          <!-- --------------------------------- -->
        </template>
      </div>
      <div
        v-else
        style="
          height: 600px;
          display: flex;
          align-content: center;
          justify-content: center;
        "
      >
        <BaseTableEmpty></BaseTableEmpty>
      </div>
    </div>
    <div class="page_box">
      <el-pagination
        @size-change="handleSizeChange"
        @current-change="handleCurrentChange"
        :page-sizes="[10, 20, 30]"
        :page-size="pageSize"
        :current-page="pageNum"
        background
        layout="total, sizes, prev, pager, next, jumper"
        :total="total"
      ></el-pagination>
    </div>
  </div>
</template>

<script>
import BaseTableEmpty from "@/components/BaseTableEmpty/index.vue";
import { getList } from "@/api/login";
import { time } from "@/utils/image";
export default {
  name: "news",
  components: { BaseTableEmpty },
  data() {
    return {
      // seleceTabId: "",
      // tabList: [
      //   { id: "1506108324052815873", name: "竞委会新闻", parentPath: "/news" },
      //   { id: "1506108399961329665", name: "赛区新闻", parentPath: "/news" },
      //   { id: "1506108451010203650", name: "其他", parentPath: "/news" },
      // ],
      loading: false,
      list: [],
      pageNum: 1,
      pageSize: 10,
      total: 0,
      contentTitle: "",
    };
  },
  created() {
    this.getList();
  },
  methods: {
    async getList() {
      try {
        this.loading = true;
        let res = await getList({
          pageNum: this.pageNum,
          pageSize: this.pageSize,
          categoryCode: "news",
          contentTitle: this.contentTitle,
        });
        this.loading = false;
        if (res.code == "0") {
          let arr = res.data.cmsContents.dataList || [];
          arr.forEach((item) => {
            item.time = time(item.contentDatetime);
          });
          this.list = arr;
          this.total = res.data.cmsContents.total;
        } else {
          this.list = [];
          this.total = 0;
        }
      } catch (error) {
        this.list = [];
        this.total = 0;
        this.loading = false;
      }
    },
    keySearch() {
      this.pageNum = 1;
      this.getList();
    },
    // selectTab(tab) {
    //   this.seleceTabId = tab.id;
    //   this.pageNum = 1;
    //   // 记录当前的二级菜单---详情页的面包屑展示
    //   sessionStorage.setItem("admin_high_light_two", JSON.stringify(tab));
    // },
    handleSizeChange(size) {
      this.pageNum = 1;
      this.pageSize = size;
      this.getList();
    },
    handleCurrentChange(page) {
      this.pageNum = page;
      this.getList();
    },
    toDetail(id) {
      this.$router.push({
        path: "/detail",
        query: {
          id,
        },
      });
    },
  },
};
</script>

<style scoped>
.head {
  background-color: #d80c24;
  height: 0.52rem;
  padding-left: 1.06rem;
  padding-right: 0.7rem;
  display: flex;
  justify-content: space-between;
  align-items: center;
}
.head_left {
  display: flex;
  align-items: center;
}
.search_box {
  position: relative;
  width: 3.25rem;
  height: 0.36rem;
  border-radius: 0.18rem;
  overflow: hidden;
}
.search_box /deep/ .el-input__inner {
  height: 0.36rem;
  padding-right: 50px;
}
.search_icon {
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  right: 0.16rem;
  font-size: 0.18rem;
  cursor: pointer;
}
.tab_item {
  margin-right: 0.4rem;
  color: #fefefe;
  padding: 0.1rem 0.32rem;
  cursor: pointer;
  border-bottom: 3px solid #d80c24;
}
.tab_select {
  border-bottom: 3px solid #ffffff;
}

.page_box {
  margin: 0.3rem 0;
  display: flex;
  justify-content: center;
}
.page_box
  /deep/
  .el-pagination.is-background
  .el-pager
  li:not(.disabled).active {
  background-color: #d80c24;
}

.news_first {
  height: 4rem;
  position: relative;
  margin-bottom: 0.3rem;
  cursor: pointer;
}
.first_img {
  width: 100%;
  height: 100%;
}
.first_bot {
  position: absolute;
  bottom: 0;
  left: 0;
  width: 100%;
  height: 1.1rem;
  background-color: rgba(62, 58, 57, 0.4);
  padding: 0 0.7rem;
  display: flex;
  flex-direction: column;
  justify-content: center;
  color: #fff;
}
.first_title {
  font-size: 16px;
  font-weight: 700;
  margin-bottom: 0.1rem;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
}
.first_content {
  width: 8.73rem;
  display: -webkit-box;
  -webkit-box-orient: vertical;
  -webkit-line-clamp: 2;
  overflow: hidden;
}

.news_two {
  padding: 0 0.7rem;
  margin-bottom: 0.2rem;
  display: flex;
  align-items: center;
  color: #3e3a39;
  cursor: pointer;
}
.news_two:last-child {
  margin-bottom: 0.3rem;
}
.news_two_img {
  width: 2.98rem;
  height: 1.68rem;
  margin-right: 0.2rem;
}
.news_two_info {
  padding-top: 0.1rem;
  height: 1.68rem;
}
.news_two_title {
  font-weight: 700;
  font-size: 18px;
  width: 7.4rem;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
}
.news_two_time {
  margin: 0.1rem 0;
}
.news_two_content {
  width: 8.22rem;
  display: -webkit-box;
  -webkit-box-orient: vertical;
  -webkit-line-clamp: 2;
  overflow: hidden;
}

.news_thr {
  padding: 0 0.7rem;
  margin-bottom: 0.2rem;
  display: flex;
  align-items: center;
  color: #3e3a39;
  cursor: pointer;
}
.news_thr:last-child {
  margin-bottom: 0.3rem;
}
.news_thr_time {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  border: 1px solid #d80c24;
  width: 0.84rem;
  height: 0.88rem;
  color: #d80c24;
  border-radius: 4px;
  margin-right: 0.1rem;
}
.news_thr_time_data {
  font-size: 34px;
  line-height: 48px;
}
.news_thr_info {
  color: #3e3a39;
  height: 0.88rem;
}
.news_thr_title {
  font-weight: 700;
  font-size: 16px;
  width: 4.8rem;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
  margin-bottom: 0.15rem;
}
.news_thr_content {
  width: 10.15rem;
  display: -webkit-box;
  -webkit-box-orient: vertical;
  -webkit-line-clamp: 2;
  overflow: hidden;
}
</style>
